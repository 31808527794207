import {
	CommentOutlined,
	EnvironmentOutlined,
	FileAddOutlined,
	HistoryOutlined,
	UserAddOutlined
} from '@ant-design/icons';
import { Button, Collapse, Descriptions, Statistic, Table, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Await, Link, Outlet, defer, redirect, useFetcher, useLoaderData, useNavigate } from 'react-router-dom';
import { getCustomerDetails } from '../../api/customer';
import { createProjectCreditNote, createProjectInvoice, getProjectDetails, projectAuditTrail, updateProjectBillingAddress, updateProjectBillingContact } from '../../api/finance';
import QuotationsDownload from '../../components/data/invoice/quotations-download-button.js';
import { ActionCenter } from '../../components/design/action-center.jsx';
import { Box } from '../../components/design/box.js';
import AddressSelect from '../../components/inputs/AddressSelect.jsx';
import ContactSelect from '../../components/inputs/ContactSelect.jsx';
import { Page } from '../../components/page';
import { FinanceProjectAuditTrailColumns, generateRouteUrl, getFinanceProjectInvoiceColumns } from '../../library/constants';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { FinanceProjectStatusDetails } from '../../library/constants/finance-project-statuses.js';
import { getCurrencyById, getEntityById, getFormattedId } from '../../library/helpers';
import { formatNumber, formatPrice } from '../../library/utilities/intl.js';
const { Panel } = Collapse

const InvoiceList = ({ items, currencyCode, projectId, ...otherProps }) => {
	const navigate = useNavigate()
	return <Table
		columns={getFinanceProjectInvoiceColumns(projectId, currencyCode)}
		dataSource={items}
		className='table--alternate table--plain'
		rowKey="id"
		pagination={false}
		onRow={(record) => ({
			onClick: (event) => {
				//Prevent click on anchor tags
				if (event.target.tagName.toLowerCase() === 'a') {
					return
				}
				navigate(generateRouteUrl('InvoiceDetails', {
					invoiceId: record.id
				}))
			}
		})}
		{...otherProps}
	/>
}

InvoiceList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	projectId: PropTypes.number.isRequired,
	currencyCode: PropTypes.oneOf(['USD', 'EUR', 'GBP']).isRequired
}

const FinanceProjectDetails = ({ title }) => {
	const { projectDetails, customerDetails, auditTrail } = useLoaderData()
	const fetcher = useFetcher()

	const getCurrency = () => {
		const currencyId = getEntityById(projectDetails.entity_id)?.currency_id
		return getCurrencyById(currencyId)
	}

	return (
		<Page className='projects-create' title={title}
			header={<div className='headerStats'>
				<Statistic
					title="Project ID"
					value={getFormattedId(projectDetails.id)}
					formatter={v => v}
				/>
				<Link to={generateRouteUrl('CustomerDetails', {
					id: customerDetails.id
				})}>
					<Statistic
						title="Company"
						value={customerDetails.company}
					/>
				</Link>
				{!!customerDetails.bad_payment_history && (
					<Statistic
						title="Bad Payment History"
						value="Yes"
						valueStyle={{ backgroundColor: 'red', color: 'white' }}
					/>
				)}
				<Statistic
					title="Payment Term"
					value={projectDetails.payment_term.name}
				/>
				<Statistic
					title="Payment Condition"
					value={projectDetails.payment_condition?.name}
				/>
				<Statistic
					title="Status"
					value={FinanceProjectStatusDetails[projectDetails.finance_status]}
				/>
				<Statistic
					title="Total Price"
					value={formatPrice(projectDetails.total_price, ERP_CURRENCY)}
				/>
				{getCurrency()?.code !== ERP_CURRENCY && (
					<Statistic
						title={`Total Price ${getCurrency()?.code}`}
						value={formatPrice(projectDetails.total_price_converted?.[getCurrency()?.code], getCurrency()?.code)}
					/>
				)}
				<Statistic
					title="Amonut Paid"
					value={formatPrice(projectDetails.total_price_received, getCurrency()?.code)}
				/>
				<Statistic
					title="Invoiced %"
					suffix='%'
					precision={2}
					value={formatNumber(projectDetails.invoiced_percentage, 'en')}
				/>
				<Statistic
					title="Account Manager"
					value={projectDetails.sales_manager?.name ?? '-'}
					formatter={String}
				/>
			</div>} >
			<ActionCenter actions={[
				{
					action: (
						<fetcher.Form method="post">
							<input type="hidden" name="action" value="createInvoice" />
							<Button
								icon={<FileAddOutlined />}
								type="primary"
								htmlType="submit"
								loading={fetcher.formData?.get('action')}
								ghost>Create Invoice</Button>
						</fetcher.Form>
					),
					isVisible: true
				},
				{
					action: (
						<Button
							onClick={() => {
								fetcher.submit({
									action: 'createCreditNote',
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							icon={<FileAddOutlined />}
							type="primary"
							loading={fetcher.json?.action === 'createCreditNote'}
							ghost
						>Create Credit Note</Button>
					),
					isVisible: true,
				},
				{
					action: (
						<AddressSelect
							customerId={customerDetails.id}
							onSelect={(addressId) => {
								fetcher.submit({
									action: 'updateBillingAddress',
									addressId,
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							defaultValue={projectDetails.billing_address_id}
							excludeAddressIds={[projectDetails.billing_address_id]}
							renderSelected={() => (
								<Button
									icon={<EnvironmentOutlined />}
									type="primary" ghost>
									{projectDetails.billing_address_id ? 'Update Billing Address' : 'Add Billing Address'}
								</Button>
							)}
						/>
					),
					isVisible: true
				},
				{
					action: (
						<ContactSelect
							customerId={customerDetails.id}
							onSelect={(contactId) => {
								fetcher.submit({
									action: 'updateBillingContact',
									contactId,
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							defaultValue={projectDetails.billing_contact_id}
							excludeAddressIds={[projectDetails.billing_contact_id]}
							renderSelected={() => (
								<Button
									icon={<UserAddOutlined />}
									type="primary" ghost>
									{projectDetails.billing_contact_id ? 'Update Billing Contact' : 'Add Billing Contact'}
								</Button>
							)}
						/>
					),
					isVisible: true
				},
				// {
				// 	action: (
				// 		<Button
				// 			onClick={() => alert('Not implemented')}
				// 			icon={<CloseOutlined />}
				// 			type="primary" ghost>Close Project</Button>
				// 	),
				// 	isVisible: true
				// },
				{
					action: (
						<Button
							href='#projectInvoices'
							type="primary" ghost>View Invoices</Button>
					),
					isVisible: true,
				},
			]} />
			<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Panel header='Documents' key="1">
					<div className="actions align--left">
						{!!projectDetails?.client_quotations && <QuotationsDownload projectID={projectDetails?.id} quotations={projectDetails.client_quotations} />}
						{/* <Button
							onClick={() => alert('Not implemented')}
							icon={<FilePdfOutlined />}
							type="primary">Purchage Order to Supplier</Button> */}
					</div>
				</Panel>
			</Collapse>
			<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Panel header={<><CommentOutlined style={{ fontSize: '1.5rem', verticalAlign: 'sub', marginRight: '8px' }} /> Comments from Sales/Order Manager</>} key="1">
					<Descriptions>
						<Descriptions.Item label="Comment">{projectDetails.internal_comment}</Descriptions.Item>
					</Descriptions>
				</Panel>
			</Collapse>

			{!!projectDetails.invoices && (
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/data.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Quotation Information</>} key="1">
						<Box type={Box.BoxTypes.GRAY}>
							<Descriptions title="Company Information">
								<Descriptions.Item label="Company Name">{projectDetails.customer.company}</Descriptions.Item>
								<Descriptions.Item label="Billing Contact Name">{projectDetails.billingContact.first_name} {projectDetails.billingContact.last_name}</Descriptions.Item>
								<Descriptions.Item label="Phone No.">{projectDetails.billingContact.phone_country_code} {projectDetails.billingContact.phone_number}</Descriptions.Item>
							</Descriptions>
						</Box>
						<Box type={Box.BoxTypes.GRAY}>
							<Descriptions>
								<Descriptions.Item label="Total Invoice Value excl. VAT">{formatPrice(projectDetails.total_invoiced_price_excl_vat, getCurrency()?.code)}</Descriptions.Item>
								<Descriptions.Item></Descriptions.Item>
								<Descriptions.Item label="Invoiced %"><Box type={Box.BoxTypes.GRAY2} size={Box.BoxSizes.MINI}>{formatNumber(projectDetails.invoiced_percentage, 'en')} %</Box></Descriptions.Item>
								<Descriptions.Item label="Total Invoice Value incl. VAT">{formatPrice(projectDetails.total_invoiced_price, getCurrency()?.code)}</Descriptions.Item>
								<Descriptions.Item label="Total Price">{formatPrice(projectDetails.total_price, getCurrency()?.code)}</Descriptions.Item>
								<Descriptions.Item label="Paid"><Box type={Box.BoxTypes.GRAY2} size={Box.BoxSizes.MINI}>{formatPrice(projectDetails.total_price_received, getCurrency()?.code)}</Box></Descriptions.Item>
							</Descriptions>
						</Box>
						<Box header={<h3>Invoices</h3>} type={Box.BoxTypes.GRAY}>
							<InvoiceList id="projectInvoices" items={projectDetails.invoices} projectId={projectDetails.id} currencyCode={getCurrency()?.code} />
						</Box>
					</Panel>
				</Collapse>
			)}
			<React.Suspense>
				<Await
					resolve={auditTrail}
					errorElement={
						<p>Error loading audit trail!</p>
					}
				>
					{(logs) => (
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header={<><HistoryOutlined /> Audit Trail</>} key="1">
								<Table
									columns={FinanceProjectAuditTrailColumns}
									dataSource={logs}
									rowKey="id"
								/>
							</Panel>
						</Collapse>
					)}
				</Await>
			</React.Suspense>
			<Outlet />
		</Page >
	)
}

FinanceProjectDetails.propTypes = {
	title: PropTypes.string
}

FinanceProjectDetails.Actions = {
	updateBillingContact: async ({ params, data }) => {
		const { contactId } = data
		const { projectId } = params
		try {
			const res = await updateProjectBillingContact(projectId, contactId)
			message.success(res)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	updateBillingAddress: async ({ params, data }) => {
		const { addressId } = data
		const { projectId } = params
		try {
			const res = await updateProjectBillingAddress(projectId, addressId)
			message.success(res)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	createInvoice: async ({ params }) => {
		const { projectId } = params
		try {
			const { invoice } = await createProjectInvoice(projectId)
			message.success("Invoice created successfully")
			return redirect(generateRouteUrl('InvoiceDetails', {
				invoiceId: invoice
			}))
		}
		catch (error) {
			message.error(`Failed with error: '${error.message}'`)
		}
		return false
	},
	createCreditNote: async ({ params }) => {
		const { projectId } = params
		try {
			const { invoice } = await createProjectCreditNote(projectId)
			message.success("Credit Note created successfully")
			return redirect(generateRouteUrl('InvoiceDetails', {
				invoiceId: invoice
			}))
		}
		catch (error) {
			message.error(`Failed with error: '${error.message}'`)
		}
		return false
	},
}

FinanceProjectDetails.Loader = async ({ params }) => {
	if (isNaN(params.projectId)) {
		throw new Error('Invalid Project ID')
	}
	const projectDetails = await getProjectDetails(params.projectId)
	if (!projectDetails) {
		throw new Error('Invalid Project')
	}

	const customerDetails = await getCustomerDetails(projectDetails.customer_id)
	projectDetails.billingContact = projectDetails.billing_contact_id ? customerDetails.contacts.find(({ id }) => id === projectDetails.billing_contact_id) : null

	// const basicProjectDdetails = (({
	// 	source_id, customer_id, entity_id, account_manager_id, client_order_number, payment_condition_id, payment_term_id, client_comment
	// }) => ({
	// 	source_id, customer_id, entity_id, account_manager_id, client_order_number, payment_condition_id, payment_term_id, client_comment
	// }))(projectDetails)

	// const items = await Promise.all(projectDetails.items.map(async ({ id }) => {
	// 	return await getProjectItem(id)
	// }))

	const auditTrail = projectAuditTrail(params.projectId)
	return defer({
		projectDetails,
		customerDetails,
		auditTrail,
	})
}

export default FinanceProjectDetails