import { CheckOutlined, FormOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Image, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form, useFetcher, useLoaderData } from 'react-router-dom';
import { acceptCombiRequest, getSentCombiDetails, reviseCombiRequest, updateCombiQuotationPrices } from '../../../api/combi';
import { Box } from '../../../components/design/box.js';
import SmallSection from '../../../components/design/small-section.jsx';
import Currency from '../../../components/inputs/Currency.jsx';
import DatePicker from '../../../components/inputs/DatePicker.jsx';
import { FieldWrapper, InputWrapper } from '../../../components/inputs/input-wrappers.jsx';
import Confirm from '../../../components/message/Confirm.jsx';
import { Prompt } from '../../../components/message/prompt.jsx';
import { Page } from '../../../components/page';
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { ERP_CURRENCY } from '../../../library/constants/dynamic.js';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { getProductImageUrl } from '../../../library/helpers/product_url.js';
import { formatDate, formatPrice } from '../../../library/utilities/intl';

const isVendorQuotationPriceRecieved = (quotations, vendorId) => quotations?.find(({ vendor_id }) => vendorId === vendor_id)?.status === 2

const SentCombiDetails = ({ title }) => {
    const { combiDetails } = useLoaderData()
    const fetcher = useFetcher()
    const pricesUpdateFetcher = useFetcher({ key: 'quotation-prices-update' })
    const [selectedVendor, setSelectedVendor] = useState(null)
    const [showPricesUpdate, setShowPricesUpdate] = useState(false)
    const selectedVendorQuotation = selectedVendor && combiDetails.item_vendor_quotations?.find(({ vendor_id }) => vendor_id === selectedVendor)

    useEffect(() => {
        //If prices modal is visible, and prices were updates successfully, close modal
        if (showPricesUpdate && pricesUpdateFetcher.data === true) {
            setShowPricesUpdate(false)
            setSelectedVendor(null)
        }
    }, [pricesUpdateFetcher.data])

    return (
        <Page title={title}>
            <Box header={<h3>{combiDetails.product.name}</h3>}>
                <Box size={Box.BoxSizes.SMALL} type={Box.BoxTypes.GRAY}>
                    <div style={{ display: 'grid', gridTemplateColumns: '4fr 8fr', justifyItems: 'start', alignItems: 'start' }}>
                        <div>
                            <Image
                                src={getProductImageUrl(combiDetails.product.main_photos?.[0])}
                                style={{ borderRadius: '15px' }}
                            />
                            <i>Image is for illustration.Real product may vary</i>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: '30px' }}>
                            {combiDetails.specifications.map(({ data, quantity, item_id }) => (
                                <SmallSection key={item_id} header={(
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="">Quantity : {quantity}</div>
                                        <div className="">Item Id : {item_id}</div>
                                    </div>
                                )}>
                                    <h4>Specifications</h4>
                                    <div>
                                        {data.map(({ id, name, value }) => (
                                            <div key={id}><strong>{name} </strong>: {value}</div>
                                        ))}
                                    </div>
                                </SmallSection>
                            ))}
                        </div>
                    </div>
                </Box>
            </Box>
            <Box>
                <Box
                    header={
                        <div style={{ flex: 1 }}>
                            <h3>Quantity {combiDetails.quantity}</h3>
                            <div style={{ display: 'grid', padding: '0 1rem', gridTemplateColumns: '30px repeat(9, 1fr)' }}>
                                <div></div>
                                <div>Vendor</div>
                                <div>Deadline Delivery Date</div>
                                <div>Expected Artwork recieved Date</div>
                                <div>Comment</div>
                                <div>Old Purchase price</div>
                                <div>Price/Unit</div>
                                <div>Old Shipping charges</div>
                                <div>Shipping charges</div>
                                <div>Total Amount</div>
                            </div>
                        </div>
                    }
                    size={Box.BoxSizes.SMALL}
                    type={Box.BoxTypes.GRAY}
                >
                    {combiDetails.item_vendor_quotations?.map(quotation => (
                        <SmallSection key={quotation.id}>
                            <div style={{ display: 'grid', gridTemplateColumns: '30px repeat(9, 1fr)', cursor: 'pointer' }} onClick={() => setSelectedVendor(val => val !== quotation.vendor_id ? quotation.vendor_id : null)}>
                                <div><Checkbox checked={selectedVendor === quotation.vendor_id} /></div>
                                <div>{quotation.vendor.company}</div>
                                <div>{formatDate(quotation.expected_delivery_date)}</div>
                                <div>{formatDate(quotation.expected_artwork_received_date)}</div>
                                <div>{quotation.comment}</div>
                                <div>{formatPrice(quotation.old_purchase_price, ERP_CURRENCY)}</div>
                                <div>{formatPrice(quotation.price_unit, ERP_CURRENCY)}</div>
                                <div>{formatPrice(quotation.old_shipping_charge, ERP_CURRENCY)}</div>
                                <div>{formatPrice(quotation.transport_price, ERP_CURRENCY)}</div>
                                <div>{formatPrice((quotation.price_unit * combiDetails.quantity) + quotation.transport_price, ERP_CURRENCY)}</div>
                            </div>
                        </SmallSection>
                    ))}
                </Box>
                {
                    combiDetails.status === 1 && (
                        <div className='actions align--right'>
                            {selectedVendorQuotation?.status === 1 && (
                                <Button
                                    onClick={() => setShowPricesUpdate(true)}
                                    icon={<FormOutlined />}
                                    loading={pricesUpdateFetcher.state !== 'idle'}
                                >Enter Prices Manually</Button>
                            )}
                            <Prompt
                                icon={<RetweetOutlined />}
                                title="Send Revision Request to Vendor"
                                okText="Send"
                                cancelText="Cancel"
                                onConfirm={({ comment = '' }) => {
                                    fetcher.submit({
                                        id: combiDetails.id,
                                        vendorId: selectedVendor,
                                        comment,
                                        action: 'reviseQuotation'
                                    }, {
                                        method: 'post',
                                        encType: 'application/json'
                                    })
                                }}
                                disabled={!selectedVendor}
                                loading={fetcher.json?.action === 'reviseQuotation'}
                                type='primary'
                                buttonText="Revise Prices"
                            >
                                <InputWrapper name="comment" label="Comment for Vendor" defaultValue="" textarea allowClear />
                            </Prompt>
                            <Confirm
                                icon={<CheckOutlined />}
                                modalIcon={<CheckOutlined />}
                                title="Send the confirmation to vendor"
                                content={
                                    <>
                                        <p>The selected quotation will be updated in all items that corresponds to this purchase bill and the vendor will be sent the PO for these the orders.</p>
                                        <p>Remaining vendors will receive update about cancelled project.</p>
                                    </>
                                }
                                onConfirm={() => {
                                    fetcher.submit({
                                        id: combiDetails.id,
                                        vendorId: selectedVendor,
                                        action: 'acceptQuotation'
                                    }, {
                                        method: 'post',
                                        encType: 'application/json'
                                    })
                                }}
                                disabled={!selectedVendor || !isVendorQuotationPriceRecieved(combiDetails.item_vendor_quotations, selectedVendor)}
                                loading={fetcher.json?.action === 'acceptQuotation'}
                                type='primary'
                            >Accept</Confirm>
                        </div>
                    )
                }
            </Box>
            <Modal
                title="Update Vendor Quotation Prices"
                open={!!selectedVendor && showPricesUpdate}
                onCancel={() => setShowPricesUpdate(false)}
                footer={false}
                forceRender
                destroyOnClose
            >
                <Form method="post" fetcherKey='quotation-prices-update' navigate={false}>
                    <input type="hidden" name="vendorId" value={selectedVendor} />
                    <p>Manualy update prices in case vendor is not using portal</p>
                    <div className="form-fields-wrapper form-fields-wrapper--four" style={{ alignItems: 'start' }}>
                        <FieldWrapper label="Unit Price" name="price_unit" errors={pricesUpdateFetcher.data?.errors} span={2}>
                            <Currency name="price_unit" currency={ERP_CURRENCY} />
                        </FieldWrapper>
                        <FieldWrapper label="Transport Price" name="transport_price" errors={pricesUpdateFetcher.data?.errors} span={2} >
                            <Currency name="transport_price" currency={ERP_CURRENCY} />
                        </FieldWrapper>
                        <FieldWrapper label="Expected Artwork Recieved Date" name="expected_artwork_received_date" span={3} errors={pricesUpdateFetcher.data?.errors} >
                            <DatePicker name="expected_artwork_received_date" useHidden />
                        </FieldWrapper>
                        <InputWrapper name="comment" label="Comment" defaultValue="" span={4} errors={pricesUpdateFetcher.data?.errors} textarea/>
                    </div>
                    <Divider style={{ margin: '1rem -1.5rem', width: 'calc(100% + 3rem)' }} />
                    <div className="actions">
                        <Button onClick={() => setShowPricesUpdate(false)} type="danger">Close</Button>
                        <Button
                            name="action"
                            value="updateQuotationPrices"
                            htmlType="submit"
                            type="primary"
                        >Save</Button>
                    </div>
                </Form>
            </Modal>
        </Page >
    )
}
SentCombiDetails.propTypes = {
    title: PropTypes.string
}

SentCombiDetails.Actions = {
    acceptQuotation: async ({ params, data }) => {
        const { id } = params
        const { vendorId } = data
        try {
            const msg = await acceptCombiRequest(id, vendorId)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
    reviseQuotation: async ({ params, data }) => {
        const { id } = params
        const { vendorId, comment } = data
        try {
            const msg = await reviseCombiRequest(id, vendorId, comment)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
    updateQuotationPrices: async ({ params, data }) => {
        const { id } = params
        const { vendorId, price_unit, transport_price, expected_artwork_received_date, comment } = convertDataTypes(data, {
            price_unit: parseFloat,
            transport_price: parseFloat,
        })
        try {
            const msg = await updateCombiQuotationPrices(id, vendorId, price_unit, transport_price, expected_artwork_received_date, comment)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },
}
SentCombiDetails.Loader = async ({ params }) => {
    const { id } = params
    if (isNaN(id)) {
        throw new Error('Invalid Combi ID')
    }
    const combiDetails = await getSentCombiDetails(id)

    // const itemDetails = await getProjectItem(combiDetails.item_vendor_quotations[0].item_id)
    // const productDetails = await getProductDetails(itemDetails.product?.id)
    return { combiDetails }
}

export default SentCombiDetails