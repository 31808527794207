import { Button, Form, Input, message } from 'antd';
import { debounce } from 'lodash-es';
import React, { useState } from "react";
import { useFetcher } from 'react-router-dom';
// import { fetchArtworkDetails } from '../../../api/order';
import PropTypes from 'prop-types';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { fetchArtworkDetails } from '../../../api/order';
import { uploadItemArtwork } from '../../../api/project-item/project-item.js';
import { DatePicker } from "../../../components/inputs";
import { generateRouteUrl } from '../../../library/constants';
import { CLIENT_ARTWORK_UPLOAD_OFFSET_DAYS, CLIENT_PROOF_APPROVAL_OFFSET_DAYS } from '../../../library/constants/dynamic.js';
import { ItemClientStatuses } from '../../../library/constants/ItemClientStatuses.js';
import { OrderStatuses } from '../../../library/constants/OrderStatuses.js';
import { subtractDays } from '../../../library/utilities/intl.js';
import { Box } from '../../design/box.js';
import FileManager from '../../inputs/file-manager.jsx';
const { TextArea } = Input;

const ItemTasks = ({ projectId, item }) => {
    const [artworkFiles, setArtworkFiles] = useState([])
    const [isUploadVisible, setIsUploadVisible] = useState(false)
    const fetcher = useFetcher()
    let revalidator = useRevalidator();
    const [form] = Form.useForm();

    const getClientArtworks = async () => {
        try {
            const data = await fetchArtworkDetails(item.id)
            setArtworkFiles(data.client_artwork)
        }
        catch (e) {
            message.error(e.message)
        }
    }

    const handleChange = async (data) => {
        fetcher.submit({
            ...data,
            action: 'updateItem',
        }, {
            method: 'post',
            encType: 'application/json',
            action: generateRouteUrl('ProjectItemUpdate', {
                projectId,
                itemId: item.id
            })
        })
    }

    const debouncedHandleChange = debounce(handleChange, 500)

    const handleUploadArtwork = async (imageData) => {
        try {
            const { message: msg } = await uploadItemArtwork(item.id, { image_ids: imageData })
            message.success(msg);
            getClientArtworks()
            revalidator.revalidate()
        }
        catch (error) {
            message.error(message.error);
        }
    }

    return (
        <Box type={Box.BoxTypes.GRAY}>
            <h3>{item.id} {item.product.name}</h3>
            <Form
                layout="vertical"
                autoComplete="off"
                preserve={false}
                initialValues={item}
                onValuesChange={(changedFields, values) => {
                    if (changedFields.deadline_approval_proof_vendor) {
                        const date = changedFields.deadline_approval_proof_vendor
                        const deadline_artwork_customer = subtractDays(date, CLIENT_ARTWORK_UPLOAD_OFFSET_DAYS)
                        const deadline_approval_proof_customer = subtractDays(date, CLIENT_PROOF_APPROVAL_OFFSET_DAYS)
                        form.setFieldsValue({
                            deadline_artwork_customer,
                            deadline_approval_proof_customer
                        })
                        debouncedHandleChange({
                            ...values,
                            deadline_artwork_customer,
                            deadline_approval_proof_customer,
                        })
                    }
                    else {
                        debouncedHandleChange(values)
                    }
                }}
                form={form}
            >
                <div className='form-fields-wrapper'>
                    <Form.Item name="deadline_artwork_customer" label="Deadline Artwork Customer" dependencies={['deadline_approval_proof_vendor']}>
                        <DatePicker disabledAfter={item?.deadline_approval_proof_vendor} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="deadline_approval_proof_customer" label="Deadline Approval proof Customer" dependencies={['deadline_approval_proof_vendor']} >
                        <DatePicker disabledAfter={item?.deadline_artwork_customer} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="deadline_approval_proof_vendor" label="Deadline Approval proof Vendor">
                        <DatePicker disabledAfter={item?.client_expected_delivery_date} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="Artwork Status">
                        <Input value={item.client_status >= ItemClientStatuses.UPLOAD_ARTWORK ? 'Artwork Recieved' : 'Waiting For Files'} disabled />
                    </Form.Item>
                    <Form.Item name="artwork_details" label="Artwork Details" >
                        <TextArea placeholder="Your message Here..." rows={1} allowClear />
                    </Form.Item>
                    <Form.Item label=" ">
                        <Button onClick={async () => {
                            setIsUploadVisible(true)
                            getClientArtworks()
                        }}>Upload Artwork</Button>
                    </Form.Item>
                </div>
            </Form>
            <FileManager
                files={artworkFiles}
                isOpen={isUploadVisible}
                projectId={projectId}
                onClose={() => setIsUploadVisible(false)}
                onUpload={(imageData) => handleUploadArtwork(imageData)}
            />
        </Box >
    )
}
ItemTasks.propTypes = {
    projectId: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
}

const Tasktodo = () => {
    const { projectId, items } = useLoaderData()
    const approvedItems = items.filter(({ active, order_status }) => (active === 1 && order_status !== OrderStatuses.CANCELED))
    // const fileUploaderRef = useRef([])

    return (approvedItems.map((item) => <ItemTasks key={item.id} projectId={projectId} item={item} />))
}

export default Tasktodo